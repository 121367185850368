import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Portfolio from "../components/portfolio"

export default function Home({ data }) {

  const portfolioItems = data.allMarkdownRemark.nodes

  return <Layout>

    <div className="f4 f3-ns mt3 mb4 cf">

      <p className="measure lh-title">
        Picturedigits is a design and technology lab that helps organizations
        collect and analyze data, build data exploration and explanation
        interfaces, maps, and visualizations.
      </p>

      <p className="f4">
        Say <a href="mailto:hello@picturedigits.com" className="link black dim underline">
          hello@picturedigits.com
        </a>.
      </p>

    </div>

    <Portfolio
      items={portfolioItems}
    />

  </Layout>
}

export const query = graphql`
  query PortfolioGridQuery {
    allMarkdownRemark {
      nodes {
        frontmatter {
          title
          byline
          slug
          thumbnail
          pane
          order
        }
      }
    }
  }
`