import React from "react"

export default function Portfolio({items}) {

  const sortItems = function(a, b) {
    return b.frontmatter.order - a.frontmatter.order
  }

  const portfolioItem = (x) => (<a
      key={x.frontmatter.slug}
      href={x.frontmatter.slug}
      className="mr3 mb3 db fl no-underline relative black dim"
    >

      <img src={"/images/" + x.frontmatter.thumbnail} alt={x.frontmatter.title} />

      <div className="mt1 mb3">
        <h4 className="ma0 f4 measure">{x.frontmatter.title}</h4>
        <p className="ma0 measure-narrow">{x.frontmatter.byline}</p>
      </div>
      
    </a>)

  return <div className="cf">

    <div className="fl w-100 w-two-thirds-ns">
      { items.filter(x => x.frontmatter.pane === 'main').sort(sortItems).map( portfolioItem ) }
      { items.filter(x => x.frontmatter.pane === 'bottom').sort(sortItems).map( portfolioItem ) }
    </div>

    <div className="fl w-100 w-third-ns">
      { items.filter(x => x.frontmatter.pane === 'side').sort(sortItems).map( portfolioItem ) }
    </div>

  </div>
}